import DemoSiteBanner from "components/DemoSiteBanner";
import { EmailVerificationMessage } from "components/EmailVerificationMessage";
import CookieNotice from "components/site/CookieNotice";
import Footer from "components/site/Footer";
import { Header } from "components/site/Header";
import SiteDisabled from "components/site/SiteDisabled";
import UpdateRequiredAccountDetailsModal from "components/site/UpdateRequiredAccountDetailsModal";
import { isDemoSite } from "consts/client";
import { ReadyWaitlistEntriesMessage } from "features/waitlists/components/ReadyWaitlistEntriesMessage";
import useAllAgreements from "hooks/useAllAgreements";
import useClient from "hooks/useClient";
import useCurrentUser from "hooks/useCurrentUser";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useState } from "react";
import { cn } from "utils/cn";

type Props = {
  pageName?: string;
  shouldAddContainer?: boolean;
  preventAccessIfClientDisabled?: boolean;
  isIframe?: boolean;
  shouldUseWhiteBg?: boolean;
  children: React.ReactNode;
};

const Layout = ({
  pageName = "",
  shouldAddContainer = true,
  preventAccessIfClientDisabled = false,
  isIframe = false,
  shouldUseWhiteBg = false,
  children
}: Props) => {
  const [cookieChoice, setCookieChoice] = useState<string | null>(null);

  const clientQueryInfo = useClient();
  const { data: currentUser } = useCurrentUser();
  const allAgreementsQueryInfo = useAllAgreements(false);

  useEffect(() => {
    const storedChoice = localStorage.getItem("cookieChoice");

    if (storedChoice) {
      setCookieChoice(storedChoice);
    }
  }, []);

  return (
    <>
      {isDemoSite && !isIframe && <DemoSiteBanner />}
      <Head>
        <title>
          {pageName ? `${pageName} | ` : ""}
          {clientQueryInfo.data?.name}
        </title>
      </Head>
      {!isIframe && <Header />}
      {clientQueryInfo.data?.enabled === false &&
      preventAccessIfClientDisabled ? (
        <SiteDisabled />
      ) : (
        <div
          className={cn(
            !shouldUseWhiteBg && "bg-gray-100",
            shouldAddContainer && !isIframe && "py-8 lg:py-12",
            !shouldAddContainer && !isIframe && "pb-8 lg:pb-12",
            isIframe && "min-h-screen py-6"
          )}
        >
          {shouldAddContainer && (
            <div
              className={cn("mx-auto max-w-7xl px-4", !isIframe && "sm:px-6")}
            >
              <section className="flex flex-col gap-4 px-2 sm:px-6 lg:px-0">
                <EmailVerificationMessage />
                <ReadyWaitlistEntriesMessage className="mb-6" />
              </section>
              {children}
            </div>
          )}
          {!shouldAddContainer && (
            <>
              <section className="flex flex-col">
                <div className="w-full bg-yellow-50">
                  <EmailVerificationMessage className="mx-auto w-full max-w-7xl px-4 sm:mt-0 sm:px-6" />
                </div>
                <div className="w-full bg-blue-100">
                  <ReadyWaitlistEntriesMessage className="mx-auto w-full max-w-7xl px-4 sm:mt-0 sm:px-6" />
                </div>
              </section>
              {children}
            </>
          )}
          {clientQueryInfo.data?.cookieNotice?.enabled &&
            !cookieChoice &&
            !isIframe && (
              <CookieNotice
                client={clientQueryInfo.data}
                setCookieChoice={setCookieChoice}
              />
            )}
        </div>
      )}
      {clientQueryInfo.data?.metaPixel?.enabled &&
        clientQueryInfo.data.metaPixel.pixelId &&
        !isIframe &&
        (!clientQueryInfo.data.cookieNotice.enabled ||
          cookieChoice === "accepted") && (
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${clientQueryInfo.data.metaPixel.pixelId});
          `
            }}
          />
        )}
      {!isIframe && clientQueryInfo?.data && (
        <Footer
          client={clientQueryInfo.data}
          agreements={allAgreementsQueryInfo.data || []}
          shouldAddTopBorder={shouldUseWhiteBg}
        />
      )}
      {[
        "mandarin",
        "globex",
        "kidsclub",
        "flowyoga",
        "youthzone",
        "elite-holiday-clubs",
        "teaminfinity",
        "prosports-solutions",
        "spraoiagussport",
        "discover-activity-camps-hatherop-castle",
        "cricklade-manor-prep-and-nursery",
        "westonbirt-school-ltd",
        "rupert-house-discover-camps"
      ].includes(clientQueryInfo.data?.subdomain || "") &&
        clientQueryInfo.data &&
        currentUser?.accessLevel === 1 && (
          <UpdateRequiredAccountDetailsModal
            currentUser={currentUser}
            client={clientQueryInfo.data}
            isOpen={Boolean(currentUser?.missingRequiredFieldIds?.length)}
          />
        )}
    </>
  );
};

export default Layout;

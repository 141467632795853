export const BOOKING_ADJUSTED_MESSAGE =
  "One or more items in your cart are either no longer available or the price has changed. Your cart has been adjusted.";
export const CART_ACTIVITY_PLACE_LIMIT_REACHED_MSG =
  "Sorry, the maximum place limit has been reached.";
export const CART_TICKET_LIMIT_REACHED_MSG =
  "Sorry, the limit for this ticket has been reached.";
export const CART_UPDATE_ACTIVITY_NO_LONGER_AVAILABLE_MSG =
  "Sorry, this activity is no longer available to book.";
export const CART_UPDATE_BOOKING_PROCESSING_MSG =
  "Sorry, it's not possible to update your cart as payment is currently processing.";
export const CART_UPDATE_GENERAL_ERROR_MSG =
  "Sorry, there was an error updating your cart.";
export const ATTENDEE_ALREADY_BOOKED_ERROR_MSG =
  "Sorry, this attendee cannot be added as they've already been booked for this session."; // booking single session ticket, already booked single ticket for that session OR booking all session ticket, already booked all session ticket
export const OVERLAPPING_TICKET_EXISTS_WARNING_MSG =
  "Please note that this attendee has already been booked for some of this activity's sessions."; // booking all session ticket but already booked on a single session for activity. (Doesn't block proceeding)
export const ASSIGN_ATTENDEE_GENERAL_ERROR_MSG =
  "Sorry, there was an error assigning this attendee.";

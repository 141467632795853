import VerifyEmailModal from "components/admin/VerifyEmailModal";
import { Alert } from "components/Alert";
import { Button } from "components/ui/button";
import useCurrentUser from "hooks/useCurrentUser";
import useSendVerificationEmail from "hooks/useSendVerificationEmail";
import type { ComponentProps } from "react";
import { useState } from "react";

/**
 * Renders a component that displays a message for email verification.
 */
export const EmailVerificationMessage = (
  props: Omit<ComponentProps<typeof Alert>, "children">
) => {
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);

  const { data: user } = useCurrentUser();

  const [sendVerificationEmail] = useSendVerificationEmail();

  function handleVerifyEmail() {
    setIsVerifyEmailModalOpen(true);
    sendVerificationEmail();
  }

  // Only show the message if the user is a primary admin and their email is not verified.
  if (
    (user && user?.isPrimaryAdmin && user.isEmailVerified) ||
    !user?.isPrimaryAdmin ||
    !user
  ) {
    return null;
  }

  return (
    <>
      <Alert {...props}>
        Any created activities will not be publicly visible until your email
        address has been verified.{" "}
        <Button
          variant="unstyled"
          size="unstyled"
          className="cursor-pointer underline transition hover:text-yellow-600"
          onClick={handleVerifyEmail}
        >
          Click here
        </Button>{" "}
        to verify your email address.
      </Alert>
      <VerifyEmailModal
        emailAddress={user?.email}
        isOpen={isVerifyEmailModalOpen}
        setIsOpen={setIsVerifyEmailModalOpen}
      />
    </>
  );
};

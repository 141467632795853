import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import { getErrorMessage } from "utils/getErrorMessage";

export interface UpdateUserDatailsData {
  formData: FormDataItem[];
}

export default function useUserUpdateDetails(): MutationResultPair<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateUserDatailsData,
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.post("/api/update-user", data);
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error =>
              `Failed to update user details: ${error.message}`,
            otherErrorMessage: "Failed to update user details."
          })
        );
      }
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("currentUser");
      }
    }
  );
}
